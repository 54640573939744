/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-18 17:38:40
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-24 20:29:05
 */
import { createNamespacedHelpers, mapGetters as mapRootGetters } from 'vuex'
import { numberFormat } from '@/plugins/utils'

const { mapGetters } = createNamespacedHelpers('Operate/Datapackage/Crowdattribute')

export default {
  name: 'TableList',
  data () {
    return {
    }
  },
  computed: {

    ...mapRootGetters([
      'enum_map'
    ]),

    ...mapGetters([
      'queryForm',
      'crowdattributeList',
      'getCrowdattribute'
    ]),

    dimension () {
      return this.queryForm.dimension
    },

    tableHead () {
      if (!this.dimension) {
        return ''
      }
      const list = this.enum_map.dimension_type
      for (const { id, name } of list) {
        if (id === this.dimension) {
          return name
        }
      }
    },

    tableData () {
      const data = this.getCrowdattribute(this.dimension)
      return data.map(({ label_name: name, user_count: count, total }) => {
        return {
          name,
          count: numberFormat(count),
          percent: (Math.round(count * 10000 / total) / 100).toFixed(2) + '%'
        }
      })
    }
  }
}
